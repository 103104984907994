import { useState, useEffect } from 'react';
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	Timestamp,
} from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Note from '../../components/noteView/note';

function NoteView() {
	const [data, setData] = useState([]);

	useEffect(() => {
		const q = query(collection(db, 'testNotes'), orderBy('created', 'desc'));
		const unsub = onSnapshot(q, (querySnapshot) => {
			let temp = [];
			querySnapshot.forEach((doc) => {
				temp.push({
					id: doc.id,
					value: doc.data(),
				});
			});
			setData(temp);
		});

		return () => unsub;
	}, []);

	console.log('---data : ', data);

	return (
		<div className='noteViewContainer flex-1 p-6 bg-indigo-200'>
			{data.length > 0 ? (
				<div className='grid grid-cols-3 gap-x-6'>
					{data.map((d) => (
						<Note key={d.id} data={d.value} />
					))}
				</div>
			) : (
				<h2>No data bruh</h2>
			)}
		</div>
	);
}

export default NoteView;
