import React from 'react';
import CreateTextArea from '../../components/createNote/createTextArea';
import '../../styles/sidebar.css';

function SideBar() {
	return (
		<div className='p-6 sidebarContainer flex flex-col justify-between bg-emerald-700	dark:bg-slate-800'>
			<h1 className='text-white	'>Clippy</h1>
			<CreateTextArea />
		</div>
	);
}

export default SideBar;
