import { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

function CreateTextArea() {
	const [title, setTitle] = useState('');
	const [text, setText] = useState('');

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState('');

	const handleAddNote = async (e) => {
		e.preventDefault();

		if (title.length > 0 && text.length > 0) {
			try {
				const docRef = await addDoc(collection(db, 'testNotes'), {
					title: title,
					body: text,
					created: Date.now(),
				});
				setText('');
				setTitle('');
				showSnack('Success : Notes added.');

				console.log('Document written with ID: ', docRef.id);
			} catch (e) {
				showSnack('Error : Notes added.');
				console.error('Error adding document: ', e);
			}
		} else {
			showSnack('Error : Fill form.');
		}
	};

	function showSnack(message) {
		setAlertMsg(message);
		setShowAlert(true);

		setTimeout(() => {
			setShowAlert(false);
		}, 6000);
	}

	return (
		<>
			<form onSubmit={handleAddNote} className='flex flex-col	'>
				<label
					for='small-input'
					class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
					Title
				</label>
				<input
					type='text'
					id='small-input'
					value={title}
					placeholder='Eg: Song name'
					onChange={(e) => setTitle(e.target.value)}
					class='block mb-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
				/>

				<label
					for='message'
					class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
					Add new note
				</label>
				<textarea
					id='message'
					rows='4'
					value={text}
					onChange={(e) => setText(e.target.value)}
					class='block mb-4 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-40 max-h-80'
					placeholder='Write your thoughts here...'></textarea>
				<button
					type='submit'
					class='mt-3 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>
					Add note
				</button>
			</form>
			{showAlert && (
				<div className='alert'>
					<div
						class='rounded flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3'
						role='alert'>
						<svg
							class='fill-current w-4 h-4 mr-2'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'>
							<path d='M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z' />
						</svg>
						<p>{alertMsg}</p>
						<button onClick={() => setShowAlert(!showAlert)}>
							<svg
								class='fill-current h-6 w-6 text-white-500'
								role='button'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 20 20'>
								<title>Close</title>
								<path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' />
							</svg>
						</button>
					</div>
				</div>
			)}
		</>
	);
}

export default CreateTextArea;
