import NoteView from './page/noteView';
import SideBar from './page/sidebar';

function App() {
	return (
		<div className='App flex'>
			<SideBar />
			<NoteView />
		</div>
	);
}

export default App;
