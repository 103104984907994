import React from 'react';

function Note({ data, date }) {
	console.log('--dada', data);
	// const date = new Date(data.created.nanoseconds);
	console.log(date);
	return (
		<div className='block max-w-sm p-6 m-4  bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'>
			<div class='flex flex-col justify-start'>
				<h5 class='text-gray-200 text-xl font-medium mb-2'>{data.title}</h5>
				<p class='text-gray-200 text-base mb-4'>{data.body}</p>
				<p class='text-gray-600 text-xs'>
					{/* {`${date.getDate()} - ${date.getFullYear()}`} */}
				</p>
			</div>
		</div>
	);
}

export default Note;
