// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCm4N1TxsdffnASdVddifn3_Dwm6fdBvio',
	authDomain: 'note-1534f.firebaseapp.com',
	projectId: 'note-1534f',
	storageBucket: 'note-1534f.appspot.com',
	messagingSenderId: '520974400637',
	appId: '1:520974400637:web:e9a3962ce89b85841c0514',
	measurementId: 'G-DFJ26RDPFP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
